import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Collage } from '../Collage'
import { FullScreen } from '../FullScreen'
import { fetchImages } from '../../API'

export const Gallery = () => {
  const [imageResources, setImageResources] = useState(null)
  const [albums, setAlbums] = useState([])

  const { albumParam = albums[0] } = useParams()

  useEffect(() => {
    const handleFetchImages = async () => {
      const { response } = await fetchImages()

      setImageResources(response)
      setAlbums(Object.keys(response).sort())
    }

    handleFetchImages()
  }, [])

  if (!imageResources) return null

  const album = Object.values(albums).includes(albumParam)
    ? albumParam
    : albums[0]

  const highlightImage = `${album}/highlight`
  const images = imageResources[album].filter(
    (image) => image !== highlightImage,
  )

  return (
    <>
      <FullScreen image={highlightImage} albums={albums} />
      <Collage images={images} />
    </>
  )
}
