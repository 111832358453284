import React, { useRef, useEffect } from 'react'

import './collage.scss'

export const Collage = (props) => {
  const { images } = props

  const baseUrl = 'https://res.cloudinary.com/du334txje/image/upload/'

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const imageContainerRefs = [...Array(images.length)].map(() => useRef(null))

  const imagesPerGrid = 4

  const rowsCount = Math.ceil(images.length / imagesPerGrid)

  const defaultTemplateAreas = `'a b''a c''d c'`

  let templateAreas = ''

  for (let i = 0; i < rowsCount; i++) {
    const repeatedAreas = defaultTemplateAreas
      .replace('a b', `a${i} b${i}`)
      .replace('a c', `a${i} c${i}`)
      .replace('d c', `d${i} c${i}`)

    templateAreas += repeatedAreas
  }

  let currRow = 0

  useEffect(() => {
    imageContainerRefs.forEach((imageContainerRef) => {
      const imageContainer = imageContainerRef.current
      const containerWidth = imageContainer ? imageContainer.offsetWidth : 0
      const containerHeight = imageContainer ? imageContainer.offsetHeight : 0

      const containerSizeParam = `w_${containerWidth},h_${containerHeight},`
      const imageParams = `${containerSizeParam}c_fill,dpr_auto,q_auto,g_auto/`

      const imageId = imageContainer.dataset.bg

      const imageSrc = `${baseUrl}${imageParams}${imageId}`

      imageContainer.style.backgroundImage = `url(${imageSrc})`

      setTimeout(() => {
        imageContainer.style.opacity = 1
      }, 3000)
    })
  }, [imageContainerRefs])

  return (
    <div
      className="collage"
      style={{
        gridTemplateAreas: `${templateAreas}`,
      }}
    >
      {images.map((imageId, index) => {
        const areas = ['a', 'b', 'c', 'd']
        const areaIndex = index % areas.length

        const gridArea = areas[areaIndex] + currRow.toString()

        if (areaIndex === areas.length - 1) {
          currRow += 1
        }

        return (
          <div
            key={`image-${imageId}`}
            className="imageContainer"
            ref={imageContainerRefs[index]}
            data-bg={imageId}
            style={{ gridArea: gridArea }}
          />
        )
      })}
    </div>
  )
}
