const fetchImagesEndpoint =
  'https://derrickriner.com/.netlify/functions/api/resources'

export const fetchImages = async () => {
  try {
    const result = await fetch(fetchImagesEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const response = await result.json()

    return { response }
  } catch {
    return { success: false }
  }
}
