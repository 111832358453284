import React, { useRef, useEffect, useState } from 'react'
import './fullScreen.scss'

export const FullScreen = (props) => {
  const { image, albums } = props

  const imageContainerRef = useRef()

  const [imageWidthParam, setImageWidthParam] = useState('')

  useEffect(() => {
    const imageContainer = imageContainerRef.current
    const width = imageContainer
      ? Math.ceil(imageContainer.offsetWidth * 1.5)
      : 0

    setImageWidthParam(`w_${width},`)
  }, [imageContainerRef])

  const imageSrc = `https://res.cloudinary.com/du334txje/image/upload/${imageWidthParam}dpr_auto,q_auto/${image}`

  return (
    <div className="fullScreen">
      <div
        className="fullScreenImage fullScreenImageAnimated"
        ref={imageContainerRef}
      >
        <img src={imageSrc} alt="page title" />
      </div>
      {
        <div className="title">
          <h1 className="textTitle">Derrick Riner</h1>
          <div className="dropdown">
            <h2 className="textSubTitle">Photography</h2>
            <div className="dropdownContent">
              {albums.map((album) => {
                const albumName = album.charAt(0).toUpperCase() + album.slice(1)
                return (
                  <a href={`/${album}`} key={`dropdown-list-${album}`}>
                    {albumName}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      }
    </div>
  )
}
